.campaign-modal {
    height: 280px;
}
.form-controls-row {
    margin-bottom: 12px;
}
.form-control {
    font-weight: 500;
    font-size: 14px;
    height: 36px;
}
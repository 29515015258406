.upload-history-container .originalFileName-header {
    width: 20%;
}
.upload-history-container .rejectedRecordCount-header {
    width: 15%;
}
.upload-history-container .uploadedBy-header,
.upload-history-container .totalRecordCount-header,
.upload-history-container .validRecordCount-header,
.upload-history-container .uploadedDateTime-header {
    width: 14%;
}
.upload-history-container .actions-header {
    width: 9%;
}
.campaign-list-container .actions-header {
    width: 4%;
}
.campaign-list-container .campaignName-header {
    width: 13%;
}
.campaign-list-container .campaignTypeName-header {
    width: 12%;
}
.campaign-list-container .callFlowName-header {
    width: 14%;
}
.campaign-list-container .displayedNumber-header,
.campaign-list-container .returnNumber-header {
    width: 9%;
}
.campaign-list-container .throttle-header {
    width: 7%;
}
.campaign-list-container .remainingMembers-header,
.campaign-list-container .activeCalls-header,
.campaign-list-container .activeTransfers-header,
.campaign-list-container .userCreatedDateTime-header {
    width: 8%;
}